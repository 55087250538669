// ----------------------------------------------------------------------

export function typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-caption': {
            color: '#919EAB',
          },
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
