/* eslint-disable consistent-return */
import axios from 'axios';
import { API_URL } from 'src/config-global';
// eslint-disable-next-line import/no-cycle
import {
  getJwt,
  getRefresh,
  setRefresh,
  setSession,
  setUserId,
  TOKEN,
} from 'src/auth/context/jwt/utils';
// eslint-disable-next-line import/no-cycle
import UserService from 'src/service/UserService';

import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    Pragma: 'no-cache',
    CacheControl: 'no-cache',
    Expires: '0',
    usertype: 'admin',
    Authorization: getJwt(),
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getJwt();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response) {
      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.data.errorCode === 'COMMON_ACCESS_DENIED' ||
        error?.response?.data?.message === 'Access is denied'
      ) {
        tokenRefresh()
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            alert('토큰이 만료되었습니다. 다시 로그인해주세요.');
            sessionStorage.removeItem(TOKEN);
            sessionStorage.removeItem('USER_SESSION');

            window.location.href = paths.auth.jwt.login;

            return Promise.reject(err);
          });
      }
    }

    return Promise.reject((error?.response && error?.response?.data) || 'Something went wrong');
  }
);

export const tokenRefresh = async () => {
  try {
    return new Promise((resolve, reject) => {
      if (getJwt() === null) {
        window.location.href = paths.auth.jwt.login;
      }
      UserService.refresh(getJwt(), getRefresh())
        .then((result) => {
          const { accessToken, refreshToken, localId } = result;

          setSession(accessToken);
          setRefresh(refreshToken);
          setUserId(localId);
          resolve(accessToken);
        })
        .catch((err) => {
          reject(err?.message);

          console.log('Error occured while refresh token: ', JSON.stringify(err, null, 2));
        });
    });
  } catch (e) {
    console.log('Unable to refresh Token: ', JSON.stringify(e, null, 2));
  }
};

export default axiosInstance;
