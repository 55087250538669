import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
//
import AdminService from 'src/service/AdminService';
import UserService from 'src/service/UserService';
import { AuthContext } from './auth-context';
import {
  getJwt,
  getRefresh,
  getUserId,
  isValidToken,
  removeAllSession,
  setRefresh,
  setSession,
  setUserId,
} from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REFRESH') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = getJwt();

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await AdminService.info(getUserId());

        dispatch({
          type: 'INITIAL',
          payload: {
            user: response,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

  const response = await UserService.login(data);

  if (response && response?.code === 0 && response.value) {
      const { accessToken, localId, refreshToken } = response.value;

      setSession(accessToken);
      setRefresh(refreshToken);
      setUserId(localId);

      initialize();
      dispatch({
        type: 'LOGIN',
        payload: {
          user: localId,
        },
      });
    } else {
      throw new Error(response?.message || 'User Not Found');
    }
  }, []);

  // refresh token
  const refreshToken = useCallback(async () => {
    const data = {
      accessToken: getJwt(),
      refreshToken: getRefresh(),
    };

    const response = await UserService.refresh(data);

    if (response) {
      // eslint-disable-next-line no-shadow
      const { accessToken, localId, refreshToken } = response;

      setSession(accessToken);
      setRefresh(refreshToken);
      setUserId(localId);

      dispatch({
        type: 'REFRESH',
        payload: {
          user: localId,
        },
      });
    } else {
      throw new Error(response?.data?.message || 'User Not Found');
    }
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    removeAllSession();
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = getUserId() && getJwt() ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      refreshToken,
    }),
    [login, logout, state, status, refreshToken]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
