import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/app'));

const UserListPage = lazy(() => import('src/pages/user/list'));
const UserEditPage = lazy(() => import('src/pages/user/edit'));

const ProviderListPage = lazy(() => import('src/pages/provider/list'));
const ProviderCreatePage = lazy(() => import('src/pages/provider/new'));
const ProviderEditPage = lazy(() => import('src/pages/provider/edit'));

const AdminListPage = lazy(() => import('src/pages/admin/list'));

const BookingListPage = lazy(() => import('src/pages/booking/list'));
const BookingEditPage = lazy(() => import('src/pages/booking/edit'));

const PlatformListPage = lazy(() => import('src/pages/platform/list'));
const CodeListPage = lazy(() => import('src/pages/code/list'));

const FaqListPage = lazy(() => import('src/pages/bbs/faq/list'));
const FaqCreatePage = lazy(() => import('src/pages/bbs/faq/edit'));
const FaqEditPage = lazy(() => import('src/pages/bbs/faq/edit'));

const NoticeListPage = lazy(() => import('src/pages/bbs/notice/list'));
const NoticeCreatePage = lazy(() => import('src/pages/bbs/notice/edit'));
const NoticeEditPage = lazy(() => import('src/pages/bbs/notice/edit'));

const SMSListPage = lazy(() => import('src/pages/message/sms/list'));

const AppMainPage = lazy(() => import('src/pages/contents/main/list'));
const AppIntroPage = lazy(() => import('src/pages/contents/intro/list'));

const LanguagePage = lazy(() => import('src/pages/language/main'));
const ServiceAreaPage = lazy(() => import('src/pages/serviceArea/main'));

const TapEditPage = lazy(() => import('src/pages/tap/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [{ element: <IndexPage />, index: true }],
  },
  {
    path: 'user',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <UserListPage />, index: true },
      { path: 'list', element: <UserListPage /> },
      // { path: 'new', element: <UserCreatePage /> },
      { path: ':id', element: <UserEditPage /> },
    ],
  },
  {
    path: 'provider',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ProviderListPage />, index: true },
      { path: 'list', element: <ProviderListPage /> },
      { path: 'new', element: <ProviderCreatePage /> },
      { path: ':id', element: <ProviderEditPage /> },
    ],
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <AdminListPage />, index: true },
      { path: 'list', element: <AdminListPage /> },
      // { path: 'new', element: <ProviderCreatePage /> },
      // { path: ':id', element: <ProviderEditPage /> },
    ],
  },
  {
    path: 'booking',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <BookingListPage />, index: true },
      { path: 'list', element: <BookingListPage /> },
      { path: ':id', element: <BookingEditPage /> },
    ],
  },
  {
    path: 'gs',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <PlatformListPage />, index: true },
      { path: 'list', element: <PlatformListPage /> },
      { path: ':id', element: <BookingEditPage /> },
    ],
  },
  {
    path: 'cdc',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <PlatformListPage />, index: true },
      { path: 'list', element: <PlatformListPage /> },
      { path: ':id', element: <BookingEditPage /> },
    ],
  },
  {
    path: 'at',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <PlatformListPage />, index: true },
      { path: 'list', element: <PlatformListPage /> },
      { path: ':id', element: <BookingEditPage /> },
    ],
  },
  {
    path: 'ts',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <PlatformListPage />, index: true },
      { path: 'list', element: <PlatformListPage /> },
      { path: ':id', element: <BookingEditPage /> },
    ],
  },
  {
    path: 'app',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <AppMainPage />, index: true },
      { path: 'main', element: <AppMainPage /> },
      { path: 'intro', element: <AppIntroPage /> },
    ],
  },
  {
    path: 'code',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <CodeListPage />, index: true },
      { path: 'list', element: <CodeListPage /> },
      { path: ':id', element: <BookingEditPage /> },
    ],
  },
  {
    path: 'system',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <LanguagePage />, index: true },
      { path: 'language', element: <LanguagePage /> },
      { path: 'serviceArea', element: <ServiceAreaPage /> },
      { path: 'code', element: <CodeListPage /> },
      { path: 'admin', element: <AdminListPage /> },
    ],
  },
  {
    path: 'bbs/faq',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <FaqListPage />, index: true },
      { path: 'list', element: <FaqListPage /> },
      { path: 'new', element: <FaqCreatePage /> },
      { path: ':id/edit', element: <FaqEditPage /> },
    ],
  },
  {
    path: 'bbs/notice',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <NoticeListPage />, index: true },
      { path: 'list', element: <NoticeListPage /> },
      { path: 'new', element: <NoticeCreatePage /> },
      { path: ':id/edit', element: <NoticeEditPage /> },
    ],
  },
  {
    path: 'message/sms',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <SMSListPage />, index: true },
      { path: 'list', element: <SMSListPage /> },
      { path: 'new', element: <NoticeCreatePage /> },
      { path: ':id/edit', element: <NoticeEditPage /> },
    ],
  },
  {
    path: 'tap',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: ':id/edit', element: <TapEditPage /> },
    ],
  },
];
