// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------
// export const API_URL = 'https://api.travut.space/v1';
// export const API_URL = 'https://stg.api.travut.space/v1';
export const API_URL = import.meta.env.VITE_API_URL;

export const SENDBIRD_KEY = '493D3517-0DD1-4367-8666-E4288991AF8F';

export const DEFAULT_LANGUAGE = 'en';

export const SENDBIRD_OVERVIEW = `https://dashboard.sendbird.com/${SENDBIRD_KEY}/desk/tickets`;
export const SENDBIRD_GROUP_CHANNEL = `https://dashboard.sendbird.com/${SENDBIRD_KEY}/group-channels`;

export const ASSETS_API = import.meta.env.VITE_ASSETS_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
