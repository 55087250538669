import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useQuery } from 'react-query';

import CodeService from 'src/service/CodeService';

export const CodeContext = React.createContext({ code: {} });

/**
 *
 * @param {*} param0
 * @returns Code 정보 관리
 */
const CodeProvider = ({ children }) => {
  const fetchData = async () => {
    const result = await Promise.all([
      CodeService.list(),
      CodeService.serviceAreaList(),
      CodeService.serviceCountryList(),
      CodeService.language(),
      CodeService.serviceCodeChildList('C15000'),
    ]);
    return {
      code: result?.[0] || [],
      language: result?.[1] || [],
      serviceConutry: result?.[2] || [],
      serviceArea: result?.[3] || [],
      allowedSourceCodeIds: result?.[4] || [],
    };
  };
  const { data, refetch, isLoading } = useQuery(['dashboard'], fetchData);

  const value = useMemo(
    () => ({
      isLoading,
      code: data?.code || [],
      serviceArea: data?.language || [],
      serviceConutry: data?.serviceConutry || [],
      language: data?.serviceArea || [],
      allowedSourceCodeIds: data?.allowedSourceCodeIds || [],
      fetchData: refetch,
    }),
    [data, isLoading, refetch]
  );

  return <CodeContext.Provider value={value}>{children}</CodeContext.Provider>;
};

CodeProvider.propTypes = {
  children: PropTypes.node,
};
export default CodeProvider;
