// eslint-disable-next-line import/no-cycle
import axios from 'src/utils/axios';
import { API_URL } from 'src/config-global';
import moment from 'moment-timezone';
import _ from 'lodash';

const list = (page, size, filters, sortOrder) =>
  new Promise((resolve, reject) => {
    const filtersCopy = _.cloneDeep(filters);

    if (filtersCopy) {
      for (const [key, value] of Object.entries(filtersCopy)) {
        if (!value) delete filtersCopy[key];
      }

      filtersCopy.type = filtersCopy?.status;

      if (filtersCopy.endDate) {
        const sd = moment(filtersCopy?.endDate).format('YYYY-MM-DD');
        filtersCopy.endDate = `${sd}T23:59:59`;
      }

      if (filtersCopy.startDate) {
        const sd = moment(filtersCopy?.startDate).format('YYYY-MM-DD');
        filtersCopy.startDate = `${sd}T00:00:00`;
      }
    }

    let url = `${API_URL}/member/?size=${size || 10}&page=${page || 1}`;

    const search = new URLSearchParams(filtersCopy).toString();

    if (filtersCopy && Object.keys(filtersCopy).length > 0) url += `&${search}`;

    axios
      .get(`${url}&sort=${encodeURIComponent(sortOrder ? sortOrder : 'createdAt#desc')}`)
      .then((response) => {
        resolve(response?.data?.value || []);
      })
      .catch(() => {
        resolve([]);
      });
  });

const totalInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/member/barInfo`)
      .then((response) => {
        resolve(response?.data?.value || {});
      })
      .catch(() => {
        resolve([]);
      });
  });
};

const info = (id) =>
  new Promise((resolve) => {
    if (id) {
      axios
        .get(`${API_URL}/member/me?id=${id}`)
        .then((response) => {
          resolve(response?.data?.value || {});
        })
        .catch(() => {
          resolve([]);
        });
    } else {
      resolve({});
    }
  });
const login = (data) =>
  new Promise((resolve, reject) => {
    axios
    .post(`${API_URL}/auth/login`, data)
    .then((response) => {
      resolve(response?.data || null);
    })
    .catch((error) => {
      reject(error);
    });
  });
const refresh = (accessToken, refreshToken) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/token`, { accessToken, refreshToken })
      .then((response) => {
        resolve(response?.data?.value || {});
      })
      .catch((err) => {
        resolve([]);
      });
  });

const update = (param) =>
  new Promise((resolve, reject) => {
    axios
      .request({
        method: 'put',
        url: `${API_URL}/member/${param.id}`,
        data: param,
      })
      .then((response) => {
        resolve(response?.data?.value || null);
      })
      .catch(() => {
        resolve([]);
      });
  });

export default {
  list,
  info,
  login,
  refresh,
  update,
  totalInfo,
};
