/* eslint-disable prefer-promise-reject-errors */
import axios from 'src/utils/axios';
import { API_URL } from 'src/config-global';

const list = (size, page, category, keyword, sortOrder) => {
  return new Promise((resolve, reject) => {
    const sizeTemp = size ? `?size=${size}` : '';
    const pageTemp = page ? `&page=${page}` : '';

    const categoryId = category && category !== 'all' ? `&roleGroup=${category}` : '';
    const keywordTemp = keyword ? `&search=${keyword}` : '';
    const sortTemp = sortOrder ? `&sort=${encodeURIComponent(sortOrder)}` : '';

    axios
      .get(`${API_URL}/admin/list${sizeTemp}${pageTemp}${categoryId}${keywordTemp}${sortTemp}`)
      .then((response) => {
        resolve(response?.data?.value || []);
      })
      .catch(() => {
        resolve([]);
      });
  });
};
const info = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/admin/me?id=${id}`)
      .then((response) => {
        resolve(response?.data?.value || {});
      })
      .catch(() => {
        resolve([]);
      });
  });
};
const update = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/admin/${id}`, data)
      .then((response) => {
        if (response) {
          resolve(response?.data?.value || {});
        } else {
          reject('데이터 형식이 맞지 않습니다.');
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateProfile = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/admin/profile/${id}`, data)
      .then((response) => {
        resolve(response?.data?.value || {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const create = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/admin/`, data)
      .then((response) => {
        if (response) {
          resolve(response?.data?.value || {});
        } else {
          reject('데이터 형식이 맞지 않거나, 이미 존재하는 이메일 입니다.');
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  list,
  info,
  create,
  update,
  updateProfile,
};
