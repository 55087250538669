import axios from 'src/utils/axios';
import {API_URL} from 'src/config-global';

const list = () => {
  return new Promise((resolve, reject) => {
    axios
    .get(`${API_URL}/code/all`)
    .then((response) => {
      resolve(response?.data?.value || []);
    })
    .catch(() => {
      resolve([]);
    });
  });
};

const serviceAreaList = () => {
  return new Promise((resolve, reject) => {
    axios
    .get(`${API_URL}/code/serviceArea`)
    .then((response) => {
      resolve(response?.data?.value || []);
    })
    .catch(() => {
      resolve([]);
    });
  });
};

const serviceCountryList = () => {
  return new Promise((resolve, reject) => {
    axios
    .get(`${API_URL}/code/area/parent`)
    .then((response) => {
      resolve(response?.data?.value?.list || []);
    })
    .catch(() => {
      resolve([]);
    });
  });
};

const serviceAreaChildList = (parentId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/code/area/child?parentId=${parentId}&size=100`)
      .then((response) => {
        resolve(response?.data?.value?.list || []);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

const serviceCodeChildList = (parentId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/code/child?parentId=${parentId}`)
      .then((response) => {
        resolve(response?.data?.value || []);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

const language = () => {
  return new Promise((resolve, reject) => {
    axios
    .get(`${API_URL}/code/language`)
    .then((response) => {
      const res = response?.data?.value || [];

      res.sort((a, b) => (a?.id > b?.id ? 1 : -1));

      resolve(res);
    })
    .catch(() => {
      resolve([]);
    });
  });
};

const create = (pid, name, prefix = '') => {
  const param = {
    pid,
    name,
    isUse: false,
  };

  return new Promise((resolve, reject) => {
    axios
    .post(`${API_URL}/code/${prefix}`, param)
    .then((response) => {
      resolve(response?.data?.value || {});
    })
    .catch(() => {
      resolve([]);
    });
  });
};

const update = (id, data) => {
  const param = {
    ...data,
  };
  return new Promise((resolve, reject) => {
    axios
    .put(`${API_URL}/code/${id}`, param)
    .then((response) => {
      console.log(response);
      resolve(response?.data?.value || {});
    })
    .catch(() => {
      resolve([]);
    });
  });
};

const remove = (id) => {
  return new Promise((resolve, reject) => {
    axios
    .delete(`${API_URL}/code/${id}`)
    .then((response) => {
      resolve(response?.data?.value || {});
    })
    .catch(() => {
      resolve([]);
    });
  });
};

export default {
  list,
  update,
  create,
  serviceAreaList,
  serviceCountryList,
  serviceAreaChildList,
  serviceCodeChildList,
  language,
  remove,
};
